import React, { Component } from 'react';
import Swiper from "swiper";
import "../../../node_modules/swiper/dist/css/swiper.min.css";
import "./slider.scss";

export default class Slider extends Component {

    componentDidMount() {
        new Swiper ('.swiper-container', {
            // Optional parameters
            direction: 'horizontal',
            parallax: false,
            loop: true,
            /* pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                },
            }, */
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            }
        })
    }

    render() {
        return (
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="container">
                            <div className="row row-grid align-items-center">
                                <div className="col-md-12 order-md-2">
                                    <img src="https://res.cloudinary.com/polyglots-cdn/image/upload/v1567714212/polyglots/work/mmw.png" alt="iMudra" className="work-image"/>
                                </div>
                                <div className="col-md-12 order-md-1">
                                    <div className="pr-md-5 tal">
                                        <h4>
                                            IRCTC iMudra
                                        </h4>
                                        <p>
                                            IRCTC iMudra is a digital wallet under which user can deposit money in advance with IRCTC 
                                            and can be used as payment option at the time of booking tickets. The app offers several 
                                            features like Expense dashboard, Money transfer, Top Up, Card purchase, refills and exclusive offers. 
                                        </p>
                                        <p>
                                            Developed for <a target="_blank" rel="noopener noreferrer" href="http://www.perennialsys.com/">Perennial Systems</a>
                                        </p>
                                        <p>
                                            <a href="https://www.irctcimudra.com" target="_blank" rel="noopener noreferrer">Visit website</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <p>
                                    Developed using
                                    <a target="_blank" rel="noopener noreferrer" href="https://angular.io/"> Angular</a>, 
                                    <a target="_blank" rel="noopener noreferrer" href="https://ngxs.gitbook.io/ngxs/"> NGXS</a>, 
                                    <a target="_blank" rel="noopener noreferrer" href="https://valor-software.com/ngx-bootstrap/#/"> Ngx-Bootstrap</a>, 
                                    <a target="_blank" rel="noopener noreferrer" href="https://firebase.google.com/"> Firebase</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="container">
                            <div className="row row-grid align-items-center">
                                <div className="col-md-12 order-md-2">
                                    <video
                                        loop muted
                                        autoPlay
                                        className="work-video"
                                        >
                                        <source src="https://res.cloudinary.com/polyglots-cdn/video/upload/v1567714209/polyglots/work/noshhb.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div className="col-md-12 order-md-1">
                                    <div className="pr-md-5 tal">
                                        <h4>
                                            Nosh 
                                        </h4>
                                        <p>
                                            Nosh is a Japanese-Hawaiian concept serving up honest, 
                                            clean goodness in the form of build your own nourishing poke bowls and tasty nori tacos.
                                            Founded in Melbourne in 2017, Nosh is an innovator in fast-food game with a focus on real food made well and served quickly. 
                                            With four stores in Melbourne and two in Canberra, vibrant brand design and a team of staff with a passion for goodness, 
                                            Nosh is energetic, approachable and believes that all food should be fun.
                                        </p>
                                        <p>
                                            <a target="_blank" rel="noopener noreferrer" href="https://nosh.net.au">Visit website</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <p>
                                    Developed using
                                    <a target="_blank" rel="noopener noreferrer" href="https://reactjs.org/"> React</a>,
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org/"> GatsbyJS</a>,
                                    <a target="_blank" rel="noopener noreferrer" href="https://expressjs.com/"> Express</a>,
                                    <a target="_blank" rel="noopener noreferrer" href="https://developers.google.com/sheets/api/"> Google Sheets</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="container">
                            <div className="row row-grid align-items-center">
                                <div className="col-md-12 order-md-2">
                                    <img src="https://res.cloudinary.com/polyglots-cdn/image/upload/v1567714213/polyglots/work/biteApp2.png" alt="Bit-e" className="work-image"/>
                                </div>
                                <div className="col-md-12 order-md-1">
                                    <div className="pr-md-5 tal">
                                        <h4>
                                            Bit-e
                                        </h4>
                                        <p>
                                            Creating innovative banking solutions for a disruptive generation.
                                            <a target="_blank" rel="noopener noreferrer" href="https://bit-e.com.au"> Bit-e</a> is an Australian digital bank providing full suite of traditional and digital banking products including: 
                                            payments, lending, business banking and foreign transaction solutions.
                                        </p>
                                        <p>
                                            <a target="_blank" rel="noopener noreferrer" href="https://bit-e-app.firebaseapp.com/">Visit staging website</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <p>
                                    Developed using
                                    <a target="_blank" rel="noopener noreferrer" href="https://angular.io/"> Angular</a>, 
                                    <a target="_blank" rel="noopener noreferrer" href="https://ngxs.gitbook.io/ngxs/"> NGXS</a>, 
                                    <a target="_blank" rel="noopener noreferrer" href="https://valor-software.com/ngx-bootstrap/#/"> Ngx-Bootstrap</a>, 
                                    <a target="_blank" rel="noopener noreferrer" href="https://nodejs.org/"> NodeJS</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="container">
                            <div className="row row-grid align-items-center">
                                <div className="col-md-12 order-md-2">
                                    <img src="https://res.cloudinary.com/polyglots-cdn/image/upload/v1567714212/polyglots/work/self2.png" alt="DASHSelf" className="work-image"/>
                                </div>
                                <div className="col-md-12 order-md-1">
                                    <div className="pr-md-5 tal">
                                        <h4>
                                            Dash Self
                                        </h4>
                                        <p>
                                            DASHSelf enabled patients to schedule appointments online without requiring to remember another username and password. 
                                            It adheres to the same rules that common agents do. 
                                            It is a white-labeled solution allowing clients to offer an online scheduling experience that matches their brand and style.
                                        </p>
                                        <p>
                                            Developed for <a target="_blank" rel="noopener noreferrer" href="https://www.radixhealth.com/">Radix Health</a>
                                        </p>
                                        <p>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.radixhealth.com/dashself">Visit website</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <p>
                                    Developed using
                                    <a target="_blank" rel="noopener noreferrer" href="https://angular.io/"> Angular</a>, 
                                    <a target="_blank" rel="noopener noreferrer" href="https://material.angular.io/"> Angular Material</a>, 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="container">
                            <div className="row row-grid align-items-center">
                                <div className="col-md-12 order-md-2">
                                    <img src="https://res.cloudinary.com/polyglots-cdn/image/upload/v1567714216/polyglots/work/gocamp.png" alt="GoCamp" className="work-image"/>
                                </div>
                                <div className="col-md-12 order-md-1">
                                    <div className="pr-md-5 tal">
                                        <h4>
                                            GoCamp
                                        </h4>
                                        <p>
                                            GoCamp connects campers with hosts of private lands by using the sharing economy to bring 
                                            people and communities together for greater camping experiences. 
                                            They have teamed up with land owners around Australia, leveraging private property for awesome trips.
                                        </p>
                                        <p>
                                            <a target="_blank" rel="noopener noreferrer" href="http://gocamp.polyglots.io">Visit staging website</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <p>
                                    Developed using
                                    <a target="_blank" rel="noopener noreferrer" href="https://reactjs.org/"> React</a>,
                                    <a target="_blank" rel="noopener noreferrer" href="https://nextjs.org/"> NextJS</a>,
                                    <a target="_blank" rel="noopener noreferrer" href="https://expressjs.com/"> Express</a>,
                                    <a target="_blank" rel="noopener noreferrer" href="https://typeorm.io/"> TypeORM</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="swiper-pagination"></div>
            </div>
        );
    }
}
