import React from "react";
import Layout from "../components/common/layout";
import Main from "../components/common/main";
import SEO from "../components/common/seo";
import Slider from "../components/common/slider";

const intro = (
  <>
    <div className="vb-150" />
    <div className="container line-container d-flex">
      <div className="col px-0">
        <div className="row">
          <div className="col-lg-10">
            <h1 className="display-2  text-white">
                Adaptive by nature, 
                <span>intelligent by design.</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div className="pt-100" />
  </>
);

/* const body = (
  <section className="section bg-secondary">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="row row-grid">
            <div className="col-lg-12">
                <div className="row row-grid align-items-center">
                    <div className="col-md-12 order-md-1">
                      <div className="pr-md-5">
                        <h1>We are almost there. Just a day more 🛬 </h1>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
); */

const slideShow = (
  <section className="section bg-secondary">
    <Slider></Slider>
  </section>
)

const Work = props => {
  return (
    <Layout>
      <SEO title="Work" keywords={[`polyglots`, `angular`, `react`, 'node', 'software development', 'consulting']}/>
      <Main lineColor="line-yellow">{intro}</Main>
      {slideShow}
    </Layout>
  )
}

Work.propTypes = {}

export default Work
